import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../coreFIles/helper"; 

export const LoginAction = (data) => {
  return postRequest("adminLogin", data).then((res) => {
    return res.data;
  });
};
export const getsettingsListAction = (data) => {
  return postRequest("getsettingsList", data).then((res) => {
    return res.data;
  });
};
export const updatesettingsListsAction = (data) => {
  return postRequest("updatesettingsLists", data).then((res) => {
    return res.data;
  });
};

// API Calls
export const getHobsonCategorylistAction = (data) => {
  return postRequest("getallcategories", data).then((res) => {
    return res.data;
  });
};

export const getHobsonProductslistAction = (data) => {
  return postRequest("getHobsonProductslist", data).then((res) => {
    return res.data;
  });
};
export const UpdateProductslistthreadAction = (data) => {
  return postRequest("UpdateProductslistthread", data).then((res) => {
    return res.data;
  });
};

export const getHobsonProductsPriceAction = (data) => {
  return postRequest("getHobsonProductsPrice", data).then((res) => {
    return res.data;
  });
};
// Category page APIs
export const getCatListAction = (data) => {
  return postRequest("getCatList", data).then((res) => {
    return res.data;
  });
};
export const getTotalCatListAction = (data) => {
  return postRequest("getTotalCatList", data).then((res) => {
    return res.data;
  });
};
export const CatBlockUnBlockAction = (data) => {
  return postRequest("CatBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

export const getCategoryDetailsAction = (data) => {
  return postRequest("getCategoryDetails", data).then((res) => {
    return res.data;
  });
};
export const UpdateCatAction = (data) => {
  return postRequest("UpdateCat", data).then((res) => {
    return res.data;
  });
};

export const UpdateCatOrderAction = (data) => {
  return postRequest("UpdateCatOrder", data).then((res) => {
    return res.data;
  });
};
// sub cat
export const getSubCatListAction = (data) => {
  return postRequest("getSubCatList", data).then((res) => {
    return res.data;
  });
};
export const getTotalSubCatListAction = (data) => {
  return postRequest("getTotalSubCatList", data).then((res) => {
    return res.data;
  });
};

// Filters 
export const getFilterTypesListAction = (data) => {
  return postRequest("getFilterTypesList", data).then((res) => {
    return res.data;
  });
};
export const getFilterListAction = (data) => {
  return postRequest("getFilterList", data).then((res) => {
    return res.data;
  });
};
export const getTotalFiltersListAction = (data) => {
  return postRequest("getTotalFiltersList", data).then((res) => {
    return res.data;
  });
};
export const FilterBlockUnBlockAction = (data) => {
  return postRequest("FilterBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

// users 
export const getMonthlySpendlistAction = (data) => {
  return postRequest("getMonthlySpendlist", data).then((res) => {
    return res.data;
  });
};
export const getTieredGrouplistAction = (data) => {
  return postRequest("getTieredGrouplist", data).then((res) => {
    return res.data;
  });
};
export const getindustrylistAction = (data) => {
  return postRequest("getindustrylist", data).then((res) => {
    return res.data;
  });
};
export const getSearchSuburbAction = (data) => {
  return postRequest("getSearchSuburb", data).then((res) => {
    return res.data;
  });
};
export const CreateUserAction = (data) => {
  return postRequest("CreateUser", data).then((res) => {
    return res.data;
  });
};
export const UpdateUserAction = (data) => {
  return postRequest("UpdateUser", data).then((res) => {
    return res.data;
  });
};
export const getUserslistAction = (data) => {
  return postRequest("getUserslist", data).then((res) => {
    return res.data;
  });
};
export const getUserDetailsAction = (data) => {
  return postRequest("getUserDetails", data).then((res) => {
    return res.data;
  });
};
export const getTotalUsersListAction = (data) => {
  return postRequest("getTotalUsersList", data).then((res) => {
    return res.data;
  });
};
export const UserDeleteAction = (data) => {
  return postRequest("UserDelete", data).then((res) => {
    return res.data;
  });
};
export const UserBlockUnBlockAction = (data) => {
  return postRequest("UserBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

export const saveCreditnoteAction=(data)=>{
  return postRequest("saveCreditnote",data).then((res)=>{
    return res.data;
  })
}
export const getCreditNoteAction=(data)=>{
  return postRequest("getCreditnotes",data).then((res)=>{
    return res.data;
  })
}
export const CreditDeleteAction=(data)=>{
  return postRequest("CreditDelete",data).then((res)=>{
    return res.data;
  })
}

// products
export const getProductslistAction = (data) => {
  return postRequest("getProductslist", data).then((res) => {
    return res.data;
  });
};
export const getTotalProductsListAction = (data) => {
  return postRequest("getTotalProductsList", data).then((res) => {
    return res.data;
  });
};

export const getProductDetailsAction = (data) => {
  return postRequest("getProductDetails", data).then((res) => {
    return res.data;
  });
};
export const getProductContractUsersAction = (data) => {
  return postRequest("getProductContractUsers", data).then((res) => {
    return res.data;
  });
};
export const getAllActiveUsersListAction = (data) => {
  return postRequest("getAllActiveUsersList", data).then((res) => {
    return res.data;
  });
};
export const getProductsPriceAction= (data)=>{
  return postRequest("getproductsPrice",data).then((res)=>{
    return res.data
  })
}

export const saveproductuserAction= (data)=>{
  return postRequest("saveproductuser",data).then((res)=>{
    return res.data
  })
}
export const UsercontactPriceideleteAction= (data)=>{
  return postRequest("UsercontactPriceidelete",data).then((res)=>{
    return res.data
  })
}

// orders
export const getOrderslistAction = (data) => {
  return postRequest("getOrderslist", data).then((res) => {
    return res.data;
  });
};
export const getTotalOrdersListAction = (data) => {
  return postRequest("getTotalOrdersList", data).then((res) => {
    return res.data;
  });
};

export const getOrdersDetailsAction = (data) => {
  return postRequest("getOrdersDetails", data).then((res) => {
    return res.data;
  });
};
export const getorderStatusAction = (data) => {
  return postRequest("getorderStatus", data).then((res) => {
    return res.data;
  });
};
export const updateOrderStatusAction=(data)=>{
  return postRequest("updateOrderStatus",data).then((res)=>{
    return res.data;
  })
}


