import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import Pagination from './Pagination';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { getProductslistAction, getTotalProductsListAction } from '../Action/action';

const Products = () => {

	const [ProductList, setProductList] = useState([]);
	const [page, setPage] 				= useState(1); 
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(1);
	const [spinloader, setspinloader] 				= useState(0);
	const [startIndex, setstartIndex] 				= useState(0);	
	
   useEffect(()=>{
	   setspinloader(1);
	    getTotalProductListAPI();
        getProductslist();       
    },[]);
  
  const SrNo = (index) => {
		return startIndex + index + 1;
	  }; 
     
  const getTotalProductListAPI = async () => {
        let res = await getTotalProductsListAction();
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
		
		
    }
   
  const getProductslist = async () => {
		let res = await getProductslistAction({page:page});
		if (res.success) {
			let data = res.data;			
			setProductList(data);	
			setspinloader(0);
		}
		else
		{
			setProductList([]);	
			setspinloader(0);
		}
	}
  
  const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		setstartIndex(offset);	
		let res = await getProductslistAction({page:offset});
        if (res.success) {
            setProductList(res.data);
        }
        else
        {
			setProductList([]);	
		}

  }	
  
   
    

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        
        <Header />       
        <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Products</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Products</span>
						</div>
						<div className="col-md-6 text-end">					
						</div>
					</div>
                </div>
                
                <div className="card-body">
                
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>Sr.</th>
                          <th>Hobson part no.</th>
                          <th>Desc.</th>     
                          <th>Pack quantity</th>                          
                          <th>Pack weight</th>                         
                          <th>Sell qty min.</th>
                          <th>Sell qty inc.</th>
                          <th>Finish</th>
                          <th>Spec</th>
                          <th>Size</th>
                          <th>Length</th>
                          <th>Price per</th>
                          <th style={{ width: 160 }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        { spinloader==0 ? (
						ProductList.length > 0  ?
							ProductList.map((prod,index)=>{
								return(
									<tr key={index}>
										<td>{SrNo(index)}</td>
										<td>{prod.hobson_part}</td>
										<td>{prod.description}</td>		
										<td>{prod.pack_quantity}</td>					
										<td>{prod.pack_weight}</td>					
										<td>{prod.sell_quantity_minimum}</td>					
										<td>{prod.sell_quantity_increment}</td>					
										<td>{prod.finish}</td>					
										<td>{prod.spec}</td>					
										<td>{prod.size}</td>					
										<td>{prod.length}</td>					
										<td>{prod.price_per}</td>	
										<td>
										<Link to={`${config.baseUrl}edit-product/` + prod.id}>
											<button className='btn btn-primary editbtn'><i className="far fa-edit"></i> Edit</button>
										</Link>										
										</td>	
																		
									</tr>
								)
							})
							:
							(
								<tr><td colspan="13" className="text-center">No product found.</td></tr>
							)
						
						):
							(
								<tr><td colspan="13" className="text-center"><img className="loaderImg search-loader" src={config.baseUrl + "images/loader.gif"} height={20} width={20} /></td></tr>
							) }
                      
                      </tbody>
                    </table>
                  </div>
                   {totalPages > 0 ?										  
						 <Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
						 : ''
					 }	
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Products;
